//import React, { useEffect, useState } from 'react';
import { Field, RichText, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type GeneralContentProps = StyleguideComponentProps & {
	fields: {
		sectionTitle: Field<string>;
		title: Field<string>;
		description: Field<string>;
		content: Field<string>;
		'background-image-fill': Field<boolean>;
		'background-image': ImageField;
		'background-full-page-width': Field<boolean>;
		'background-color': {
			id: string;
			url: string;
			name: string;
			displayName: string;
			fields: {
				Key: Field<string>;
				Phrase: Field<string>;
			};
		};
	};
};

function onlySpaces(str: string) {
	return str.trim().length === 0;
}

//const preprocessContent = (content: string) => {
//	const parser = new DOMParser();
//	const doc = parser.parseFromString(content, 'text/html');
//	doc.querySelectorAll('li').forEach((li) => {
//		const anchors = Array.from(li.querySelectorAll('a'));
//		if (anchors.length > 1) {
//			anchors.slice(0, -1).forEach(anchor => anchor.remove());
//		}
//	});

//	return doc.body.innerHTML;
//};

const GeneralContent = (props: GeneralContentProps): JSX.Element => {
	const {
		'background-image': backgroundImage = { value: { src: '' } },
		'background-color': backgroundColor = { fields: { Phrase: { value: '' } } },
		'background-image-fill': backgroundImageFill = { value: false },
	} = props.fields || {};

	const backgroundStyle: React.CSSProperties = {};

	if (backgroundImageFill?.value && backgroundImage?.value?.src) {
		backgroundStyle.backgroundImage = `url(${backgroundImage?.value?.src})`;
		backgroundStyle.backgroundSize = 'cover';
		backgroundStyle.backgroundPosition = 'center';
		backgroundStyle.padding = '2rem 0';
		backgroundStyle.margin = '2rem 0';
	} else if (backgroundColor && backgroundColor?.fields?.Phrase?.value) {
		backgroundStyle.backgroundColor = backgroundColor?.fields?.Phrase?.value;
		backgroundStyle.padding = '3rem 0';
		backgroundStyle.margin = '2rem 0';
	}

	//const [preprocessedContent, setPreprocessedContent] = useState('');

	//useEffect(() => {
	//	if (props.fields?.content?.value) {
	//		setPreprocessedContent(preprocessContent(props.fields.content.value));
	//	}
	//}, [props.fields?.content?.value]);

	return (
		<div className="full-bg" style={backgroundStyle}>
			<div className="general-content">
				{props.fields?.sectionTitle && !onlySpaces(props.fields.sectionTitle?.value) && (
					<p className="section-title content-block">{props.fields?.sectionTitle?.value}</p>
				)}
				{props.fields?.title && !onlySpaces(props.fields.title?.value) && (
					<h1 className="heading-1">{props.fields?.title?.value}</h1>
				)}
				{props.fields?.description && !onlySpaces(props.fields.description?.value) && (
					<RichText className="lead" tag="p" field={props.fields?.description} />
				)}
				{props.fields?.content && !onlySpaces(props.fields.content?.value) && (
					<RichText className="custom-richtext" tag="div" field={props.fields?.content} />
				//	<RichText className="custom-richtext" tag="div" field={{ value: preprocessedContent }} />
				)}
			</div>
		</div>
	);
};

export default GeneralContent;
